<template>
    <div>
        <br>
    <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> List Report</p>
        <br>
        <br>
       <div class="row justify-content-center" v-if="$store.state.users">
         <div class="col-md-3" v-if="['SuperAdmin','Admin'].includes($store.state.users.level)">
             <p class="text-center font-bold p-2"> Barang/Produk</p>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/barang/produk')">
             <span class="typcn typcn-chart-area"></span> Report Barang/Produk</p>
             <hr>
             <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/barang/masuk')">
             <span class="typcn typcn-chart-area"></span> Report Barang Masuk/Stok IN</p>
             <hr>
             <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/barang/keluar')">
             <span class="typcn typcn-chart-area"></span> Report Barang Keluar/Stok OUT</p>
             <hr>
         </div>
          <div class="col-md-3" v-if="['SuperAdmin','Admin'].includes($store.state.users.level)">
             <p class="text-center font-bold p-2">Transaksi Pembelian/Masuk</p>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
              @click="$router.push('/admin/report/pembelian/periode')">
              <span class="typcn typcn-chart-area"></span> Report Pembelian Perperiode</p>
             <hr>
             <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/pembelian/perincian')">
              <span class="typcn typcn-chart-area"></span> Report Pembelian Perincian</p>
             <hr>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/pembelian/produk')">
              <span class="typcn typcn-chart-area"></span> Report Pembelian Per Produk</p>
             <hr>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/pembelian/supplier')">
              <span class="typcn typcn-chart-area"></span> Report Pembelian Per Supplier</p>
             <hr>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/pembelian/kasir')">
              <span class="typcn typcn-chart-area"></span> Report Pembelian Per Kasir</p>
             <hr>
         </div>
         <div class="col-md-3" v-if="['SuperAdmin','Admin'].includes($store.state.users.level)">
              <p class="text-center font-bold p-2">Transaksi penjualan/Keluar</p>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
              @click="$router.push('/admin/report/penjualan/periode')">
              <span class="typcn typcn-chart-area"></span> Report penjualan Perperiode</p>
             <hr>
             <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/penjualan/perincian')">
              <span class="typcn typcn-chart-area"></span> Report penjualan Perincian</p>
             <hr>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/penjualan/produk')">
              <span class="typcn typcn-chart-area"></span> Report penjualan Per Produk</p>
             <hr>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
              @click="$router.push('/admin/report/transaksi')">
              <span class="typcn typcn-chart-area"></span> Report Transaksi</p>
             <hr>
               <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/penjualan/kasir')">
              <span class="typcn typcn-chart-area"></span> Report penjualan Per Kasir</p>
             <hr>
         </div>
         <div class="col-md-3" v-if="['SuperAdmin'].includes($store.state.users.level)">
              <p class="text-center font-bold p-2">Report Akhir</p>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
              @click="$router.push('/admin/report/untung')">
              <span class="typcn typcn-chart-area"></span> Report Untung/Rugi</p>
             <hr>
             <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/report/chart')">
              <span class="typcn typcn-chart-area"></span> Report Chart</p>
             <hr>
         </div>
       </div>
     <div class="d-block d-sm-none"><br><br><br><br></div>
    </div>
</template>
<script>
import autophp from '@/plugins/autophp_kasir';
let sdb = new autophp();
export default{
    layout:'app_shop_admin'
}
</script>
<style scoped>
.grid-item {
    display: grid;
    /* repeat(jumlah column, 1fr) */
    grid-template-columns: repeat(4, 1fr);
    /* repeat(jumlah row) */
    grid-template-rows: repeat(4, 1fr); 
    grid-column-gap:2px;
    grid-row-gap: 2px;
}
</style>